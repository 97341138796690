import React, { useRef, useEffect, useState } from 'react';
import ChatMessage from './ChatMessage';

interface SearchEvent {
    completed: boolean;
    results: string[];
    search_message: string;
}

interface ChatContainerProps {
    messages: { id: string, role: string, content: string, searchEvent: SearchEvent | null}[];
    currentMessage: string;
    currentSearchEvent: SearchEvent | null;
}

const ChatContainer: React.FC<ChatContainerProps> = ({ messages, currentMessage, currentSearchEvent }) => {
    const chatWindowRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [messages, currentMessage, currentSearchEvent]);

    console.log();
    return (
        <div className={`pr-4 flex-auto overflow-y-auto`} style={{minHeight: '400px', maxHeight: `${window.document.body.clientHeight-400}px`}} ref={chatWindowRef}>
            {messages.map((msg, index) => (
                <ChatMessage key={index} role={msg.role as 'user' | 'assistant'} content={msg.content} searchEvent={msg.searchEvent} />
            ))}
            {currentMessage && (
                <ChatMessage role="assistant" content={currentMessage} searchEvent={currentSearchEvent} />
            )}
        </div>
    );
};

export default ChatContainer;