import React from "react";

const BouncingDotsLoader = ({ visible}: {visible: boolean}) => {
  return (
      <div className="bouncing-loader" style={{visibility: visible? 'visible': 'hidden'}}>
        <div></div>
        <div></div>
        <div></div>
      </div>
  );
};

export default BouncingDotsLoader;