import React from 'react';

interface ChatInputProps {
    input: string;
    setInput: (input: string) => void;
    sendMessage: () => void;
}

const ChatInput: React.FC<ChatInputProps> = ({ input, setInput, sendMessage }) => {
    return (
        <div className="flex-none items-center pt-0">
            <form className="flex items-center justify-center w-full space-x-2" onSubmit={(e) => { e.preventDefault(); sendMessage(); }}>
                <input
                    style={{fontSize: '2em'}}
                    className="flex h-16 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
                    placeholder="Schreibe deine Nachricht"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
                />
                <button
                    style={{fontSize: '2em'}}
                    type="submit"
                    className="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-[rgb(68,68,68)] hover:bg-[rgb(193,0,0)] h-16 px-4 py-2"
                >
                    Senden
                </button>
            </form>
        </div>
    );
};

export default ChatInput;
