import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { Chat } from './Chat';
import { ThreadProvider } from './ThreadContext';
import ChatComponent from './ChatComponent';
import './index.css';

const queryClient = new QueryClient()

function App() {
  return (
        <div className="flex flex-col min-h-screen bg-overlay-background bg-no-repeat bg-cover bg-left">
        <main className="flex-grow w-full max-w-4xl p-4 mx-auto">
          <ThreadProvider>
            <ChatComponent />
          </ThreadProvider>
        </main>
      </div>
  );
}

export default App;
